.copyright ul {
    display: flex;
    padding-top: 8px;
}

.copyright ul li {
    margin-right: 30px;
    position: relative;
}

.copyright ul li:not(:first-child):after {
    content: "";
    position: absolute;
    left: -19px;
    top: 10px;
    background: #302F2F;
    border-radius: 50%;
    width: 5px;
    height: 5px;
}