.sale-products .content {
    display: flex;
    justify-content: space-between;
}

.sale-products .sale-ad {
    background-image: url(../../assets/images/sale-banner.png);
    height: 660px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 100px;
    position: relative;
    width: 30%;
}

.sale-products .sale-ad h2 {
    font-family: "PoppinsBold", sans-serif;
}

.sale-products .sale-percents {
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
}

.sale-products .sale-percents p {
    border-radius: 16px;
    padding: 5px 12px;
}

.sale-products .sale-percents p:first-child {
    color: #FFF;
    background-color: #000;
    margin-right: 6px;
}

.sale-products .sale-percents p:last-child {
    color: #FF383B;
    background-color: #FF383B1A;
}

@media (max-width: 768px) {
    .sale-products .products{
        width: 100%;
    }
    .sale-products .content{
        flex-wrap: wrap;
    }
    .sale-products .sale-ad{
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 350px;
        padding: 15px;
        margin-bottom: 50px;
        margin-top: 50px;
        position: relative;
    }
}
