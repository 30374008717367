.signUp .content {
    display: flex;
    justify-content: space-between;
}

.signUp .content div:first-child {
    background-image: url(../../assets/images/signup.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.signUp .content .form {
    display: flex;
    flex-direction: column;
    padding: 200px 180px;
}

.signUp .content > div {
    width: 50%;
}

.signUp .content input {
    padding: 20px 60px;
    border: 1px solid #000;
    font-size: 18px;
}

.pageTitle {
    color: #4D4D4D;
    margin-bottom: 10px;
    text-align: center;
    font-family: "PoppinsBold", sans-serif;
}

.signUp .agreement {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.signUp .agreement input {
    margin-bottom: 0;
    position: relative;
    z-index: 9999;
    width: 21px;
    height: 21px;
    opacity: 0;
}

.signUp .agreement label {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.signUp .signup {
    background-color: #FF383B;
    border-radius: 6px;
    color: #FFF;
    padding: 15px;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    margin: 20px 0;
    font-family: "PoppinsSemiBold", sans-serif;
}

.signUp .login-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signUp .login-link a {
    margin-left: 20px;
    color: #261F1A;
    font-family: "PoppinsSemiBold", sans-serif;
    font-size: 20px;
}

.signUp .form input[type='password'] {
    margin-top: 20px;
}

.agreement .radioBtn {
    position: absolute;
    top: 2px;
    left: 0;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    background: #EBEBEB;
}

/* Create the radioBtn/indicator (hidden when not checked) */
.agreement .radioBtn:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the radioBtn when checked */
.agreement input:checked ~ .radioBtn:after {
    display: block;
}

.agreement {
    position: relative;
}

/* Style the radioBtn/indicator */
.agreement .radioBtn:after {
    left: 4px;
    top: 4px;
    width: 13px;
    height: 13px;
    background-color: #FF383B;
    border-radius: 50px;
}