.cartItem {
    display: flex;
    border-bottom: 1px solid #00000040;
    padding-left: 35px;
    padding-top: 15px;
}

.cartItem .product-title {
    color: #FF383B;
    margin-bottom: 10px;
}

.cart-content div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.cart-content {
    width: 100%;
}

.cartItem img {
    margin-right: 10px;
    height: 120px;
}

.cart-content svg {
    margin-right: 20px;   
}

.cartItem .product-price {
    text-align: right;
    padding: 0 0 15px;
}

.cartItem h4 {
    font-weight: normal;
}

.cart-content .delete-product {
    justify-content: flex-end;
    margin-bottom: 0;
}

.cart-content .delete-product svg {
    margin-right: 0;
    cursor: pointer;
}
