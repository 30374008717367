.navigation ul li {
    margin-right: 40px;
}

.navigation ul li a {
    color: #000;
    font-size: 12px;
}

.navigation ul {
    display: flex;
}

.navigation .size {
    display: flex;
    align-items: center;
}



.navigation select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.navigation {
    padding: 25px 0;
    box-shadow: 1px 4px 4px 0 #00000026;
}
.navigation .categories{
    position: relative;
}
.navigation .categories .toggleCategoryItems .categoryItem:nth-last-child(1){
    border-bottom: 0;
}
.navigation .categories .toggleCategoryItems .categoryItem{
    padding: 5px 0;
    border-bottom: 1px solid #C4C4C4;
    color: #000;
}
.navigation .categories .toggleCategoryItems{
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 10px;
    left: 0;
    background: #fff;
    box-shadow: 1px 4px 4px 0 #00000026;
    width: 100%;
    max-width: 235px;
    padding: 5px 21px;
}
.navigation .toggleCategory {
    background-color: #EFEFEF;
    padding: 15px 65px 15px 60px;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}
.navigation .toggleCategory:before {
    content: "";
    background-image: url(../../assets/images/menu-btn.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 25px;
    width: 20px;
    height: 15px;
    transform: translateY(-50%);
}

.navigation .toggleCategory:after {
    content: "";
    background-image: url(../../assets/images/select.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%);
}

.navigation .toggleCategory {
    position: relative;
    margin-right: 190px;
}

/*mobile navigation*/

.navigation.mobile-style {
    position: relative;
}
.navigation.mobile-style .size {
    max-width: 1368px;
    margin: 0 auto;
    padding: 1rem 2rem;
}
.navigation.mobile-style .size .navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}
.navigation.mobile-style .size .navbar .logo {
    font-size: 2.25rem;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}
.navigation.mobile-style .size .navbar .nav-links {
    display: flex;
    align-items: center;
}
.navigation.mobile-style .size .navbar .nav-links li {
    list-style-type: none;
}
.navigation.mobile-style .size .navbar .nav-links li a {
    text-decoration: none;
    color: #fff;
    margin: 0 1.25rem;
    font-weight: 500;
    font-size: 1.05rem;
    position: relative;
}
.navigation.mobile-style .size .navbar .nav-links li a::after {
    position: absolute;
    content: "";
    background-color: #fff;
    bottom: -5px;
    left: 0;
    width: 0%;
    height: 3px;
    transition: 0.3s ease all;
}
.navigation.mobile-style .size .navbar .nav-links li a:hover::after {
    width: 100%;
}
.navigation.mobile-style .size .navbar .nav-links button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.75rem 1rem;
    margin: 0 1rem;
    border-radius: 6px;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    font-weight: 500;
}
.navigation.mobile-style .size .navbar #burger {
    display: none;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .navigation.mobile-style .categories .toggleCategory{
        padding: 15px 40px 15px 50px;
        margin-right: 0;
        font-size: 15px;
    }
    .navigation.mobile-style .categories{
        margin-right: 20px;
    }

    .navigation.mobile-style .size .navbar .nav-links {
        position: absolute;
        min-height: 100vh;

        top: 0;
        left: 0;
        background-color: #434342eb;
        height: 100%;
        width: 225px;
        margin: 0;
        padding: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transform: translateX(-100%);
        transition: 0.4s ease all;
        z-index: 10;
    }
    .navigation.mobile-style .size .navbar .nav-links li{
        margin-top: 35px;
    }
    .navigation.mobile-style .size .navbar .nav-links li a {
        color: #fff;
    }
    .navigation.mobile-style .size .navbar .nav-links li a::after {
        background-color: #fff;
    }
    .navigation.mobile-style .size .navbar .nav-links button {
        border-color: #fff;
        color: #fff;
        padding: 0.75rem 1.5rem;
    }
    .navigation.mobile-style .size .navbar .nav-links.active {
        transform: translateX(0%);
    }
    .navigation.mobile-style .size .navbar #burger {
        display: block;
    }
}
