.banner {
  background-image: url(../../assets/images/background.png);
  padding: 150px 0 115px;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner h1,
.banner h4,
.banner p {
  color: #fff;
}

h4 {
  padding: 25px 0 40px;
}

.buy-now a {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 25px;
}

.buy-now a:first-child span {
  margin-right: 30px;
}

.buy-now a:first-child {
  background-color: #ff383b;
  margin-right: 20px;
  color: #fff;
  font-size: 24px;
}

.buy-now {
  display: flex;
}

.banner .content {
  width: 44%;
}

@media (max-width: 768px) {
  .banner {
    padding: 70px 15px 70px;
  }
  .banner .content {
    width: 100%;
  }
  h4 {
    padding: 20px 0 30px;
  }
  .buy-now a {
    font-size: 14px;
    border-radius: 5px;
    padding: 10px 25px;
  }

  .buy-now a:first-child span {
    margin-right: 15px;
  }

  .buy-now a:first-child {
    margin-right: 20px;
    font-size: 18px;
  }

}
