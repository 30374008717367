.header .size {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}

.header {
    border-bottom: 1px solid #C4C4C4;
}

.header .logo,
.header .header-icons {
    width: 20%;
}

.header input {
    width: 100%;
    border: 1px solid #BFBFBF;
    padding: 15px 20px;
    border-radius: 5px;
}

.search-form {
    position: relative;
    width: 40%;
}

.search-form svg {
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
}

.header-icons {
    text-align: right;
}

.header-icons a {
    cursor: pointer;
    position: relative;
}

.header-icons a:not(:last-child) {
    margin-right: 25px;
}
.red-circle{
    position: absolute;
    right: -7px;
    top: -7px;
    font-size: 9px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E81D1D;
    width: 14px;
    height: 14px;
    border-radius: 100%;
}

@media(max-width: 768px){
    .header .size {
        padding: 20px 5px;
        flex-wrap: wrap;
    }
    .header .header-icons {
        width: 35%;
    }
    .header .logo {
        width: 100% !important;
        text-align: center;
        margin-bottom: 15px;
    }
    .header input {
        padding: 10px 15px;
        padding-right: 44px;
    }
    .search-form {
        width: 60%;
    }
    .search-form svg {
        top: 50%;
        transform: translateY(-50%);
    }
    .header-icons a:not(:last-child) {
        margin-right: 15px;
    }
}
