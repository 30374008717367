.profile-info img {
    width: 100%;
    border-radius: 36px;
}

.profile-pic-content {
    position: relative;
}

.profile-pic svg {
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
}

.profile-info {
    /*margin-right: 265px;*/
    width: 100%;
    background: #E7E3E3;
    margin-top: 35px;
}

.profile-pic {
    position: absolute;
    top: -30px;
    cursor: pointer;
    z-index: 99999;
}

.profile-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 330px 0 70px 0;
    position: relative;
}

.profile-nav a {
    color: #000;
    font-size: 36px;
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
}

.profile-nav .active {
    background-color: #FD3C40;
    color: #fff;
}

.profile-info .opacity {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 97%;
    background-color: #16161680;
    border-radius: 36px;
}
.logOut-btn{
    margin-top: 20rem;
}
