.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    margin: auto;
}

.contact-form input {
    border: 1px solid #000;
    padding: 20px 15px 20px 60px;
    outline: none;
    font-size: 16px;
    width: 100%;
}

.contact-form textarea {
    resize: none;
    height: 195px;
    border: 1px solid #000;
    outline: none;
    padding: 20px 15px 20px 60px;
    width: 100%;
    font-size: 16px;
}

.contact-form .btn {
    background-color: #FD3C40;
    padding: 10px 0;
    text-align: center;
    color: #FFF;
    font-weight: 600;
    border-radius: 9px;
    width: 220px;
    margin: 35px auto 0;
    font-size: 18px;
}

.contact-form select {
    width: 100%;
    border: 1px solid #000;
    padding: 25px 15px 25px 60px;
    font-size: 16px;
    outline: none;
    color: #888888;
}

.contact-info > h6 {
    font-weight: 600;
    margin-bottom: 15px;
}

.contact-info div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.contact-info svg {
    margin-right: 20px;
}

.contact .content {
    background-image: url(../../assets/images/account-bg.png);
    background-size: cover;
    background-position-y: -290px;
    background-repeat: no-repeat;
}

.contact-form .contact-reason:after {
    content: "";
    background-image: url(../../assets/images/CaretDown.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
}

.contact-form .contact-reason {
    position: relative;
    width: 100%;
}

.contact-form select {
    -webkit-appearance: none;
    -moz-appearance: none;
}
@media (max-width: 1024px) {
    .contact-form{
        width: 80%;
    }
}
@media (max-width: 768px) {
    .contact-form{
        width: 100%;
    }
}
