.footer {
    background-color: #E3CDCD;
    padding: 70px 0 90px;
}

.footer li a {
    font-size: 14px;
    margin-bottom: 10px;
    color: #302F2F;
}

.footer .menu-title {
    font-family: "PoppinsBold", sans-serif;
    margin-bottom: 20px;
    font-size: 18px;
}

.footer-menus {
    display: flex;
    justify-content: space-between;
}

.footer .sm-links {
    margin-top: 55px;
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 10px;
}

.footer .sm-links svg {
    cursor: pointer;
}

.footer .sm-links svg:not(:last-child) {
    margin-right: 15px;
}

@media (max-width: 768px) {
    .footer {
        padding: 25px 0;
    }

    .footer-menus{
        display: grid !important;
        grid-column-gap: 15px;
        grid-row-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }
}
