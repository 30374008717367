.about-images img{
    width: 100%;
}
.about-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 19px;
    grid-row-gap: 19px;
    margin-bottom: 28px;

}
.about p{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 42px;
    color: #000000;
    margin-bottom: 28px;
}
.about-images img:not(:last-child) {
    margin-right: 20px;
}
@media (max-width: 1024px) {
    .about-images {
        grid-template-columns: 1fr 1fr;

    }

}
@media (max-width: 768px) {
    .about-images{
        grid-template-columns: 1fr;
    }
}
