.price {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140.19%;
    /* or 50px */
    letter-spacing: 0.1em;
    color: #000000;
}

.product-single-item {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 48px;
}

.product-single-item hr {
    background: #C4C4C4;
}

.product-single-item .pr-image {
    margin-bottom: 35px;
}

.product-single-item .pr-image:nth-last-child(1) {
    margin-bottom: 0;
}

.product-single-item .pr-image img {
    width: 100%;
}

.product-single-item .pr-image {
    cursor: pointer;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
}

.product-single-item .pr-main-image img {
    width: 100%;
    max-height: 535px;
    object-fit: contain;
}

.product-single-item .pr-main-image {
    display: flex;
    flex: 1;
}

.product-single-item .pr-info .list li {
    list-style: inherit;
}

.product-single-item .pr-info .list {
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    list-style: inherit;
    letter-spacing: 0.06em;
    padding-left: 25px;
    color: #200E32;

    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    margin: 0;
    height: 82px;
}
.product-single-item .pr-info .list.open{
    height: max-content;

}

.product-single-item .pr-info .desc {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.06em;

    color: #200E32;
}

.product-single-item .pr-info .pr-info-item {
    margin-bottom: 30px;
}

.product-single-item .pr-info .pr-info-item .bold:not(.MuiSelect-select .bold) {
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    letter-spacing: 0.06em;
    color: #200E32;
    width: 40%;
    max-width: 157px;
    margin-right: 20px;
}

.product-single-item .order-details {
    margin-top: 12px;
}

.product-single-item .pr-info .buy-now a {
    width: 100%;
    text-align: center;
    justify-content: center;
}

.product-single-item .pr-info .buy-now {
    margin-right: 0;
    width: 100%;
    max-width: 347px;
}

.product-single-item .order-details-list li .order-cards .card-img {
    margin-right: 20px;
}

.product-single-item .order-details-list li {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

}

.product-single-item .order-details-list li .thin {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.06em;
    text-decoration-line: underline;

    color: #8E8E8E;
    margin-right: 21px;
    min-width: 80px;
}

.product-single-item .order-details-list li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.06em;

    color: #000000;
}

.add-feedback {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 63px;
    position: relative;
}

.add-feedback textarea {
    width: 100%;
    border: 1px solid #ABA5B2;
    min-height: 159px;
    margin-bottom: 15px;
}

.feedback-stars {
    display: flex;
    align-items: center;
    position: absolute;
    top: 14px;
    right: 41px;
}

.feedback-stars .star.active svg path {
    fill: #FFC700;

}

.feedback-stars .star {
    cursor: pointer;
}

.feedback-stars .star:hover svg path {
    fill: #FFC700;
}

.feedback-stars .star:not(.star:nth-last-child(1)) {
    margin-right: 10px;
}

.all-feedbacks {
    margin-bottom: 104px;
}

.all-feedbacks-title {
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    /* identical to box height, or 170% */
    display: flex;
    align-items: center;
    letter-spacing: 2.5px;
    color: #302F2F;
    margin-bottom: 20px;
}

.all-feedbacks .feedback .feedback-info {
    display: flex;
    flex: 1;
    max-width: 900px;
}

.all-feedbacks .feedback {
    width: 100%;

}

.all-feedbacks .feedback .avatar {
    width: 90px;
    height: 90px;
    margin-right: 30px;
    border-radius: 100%;
}

.all-feedbacks .feedback .name {
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 12px;
    /* identical to box height, or 170% */

    display: flex;
    align-items: center;
    letter-spacing: 2.5px;

    color: #000000;
}

.all-feedbacks .feedback .feedback-stars {
    position: initial;
    margin-bottom: 13px;
    justify-content: flex-end;
}

.all-feedbacks .feedback .text {
    font-size: 14px;
    line-height: 34px;
    /* or 243% */

    display: flex;
    align-items: center;
    letter-spacing: 2.5px;

    color: #000000;
    margin-bottom: 31px;
}

.all-feedbacks .feedback .share-like .icon{
    margin-left: 20px;
    cursor: pointer;
}

.all-feedbacks {

}
.feedback .sub-feedback{
    margin-left: 122px;
}

@media (max-width: 1024px) {


}

@media (max-width: 768px) {

}
