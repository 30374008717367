.sort-by {
    /*width: 20%;*/
    white-space: nowrap;
    margin-right: 28px;
    position: relative;

}

.sort-by > div {
    cursor: pointer;
    display: flex;
    width: fit-content;
}

.sort-by svg {
    margin-left: 10px;
}

.filter{
}
.sort-body {
    padding: 26px 21px;
    background: #fff;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
    position: absolute;
    right: 0;
    width: 100%;
    min-width: 200px;
    text-align: left;
    flex-direction: column;
}
.closeSort{
    cursor: pointer;
    margin-bottom: 20px;
}
.filter-body .title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
}
.filters{
    border-bottom: 1px solid #C4C4C4;
}
.filter-by-head{
    cursor: pointer;
    padding: 15px 0;

}
.filter-by-body{
    margin-bottom: 15px;

}
.Sorts{
    display: flex;
    flex-direction: column;
}
.filter-by{
    border-top: 1px solid #C4C4C4;
}
.category-body{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 260px;
}
@media (max-width: 768px) {
    .sort-body{
        width: 100% !important;
    }
}

