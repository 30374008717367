.howItWorks .content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 200px;
}



.howItWorks .step-rows .step-row:nth-child(even) {
    flex-direction: row-reverse;
}
.howItWorks .step-rows .step-row{
    display: flex;
    align-items: center;
}
.howItWorks .step-rows .step-row > *:nth-last-child(1){
    border-left: 4px solid #FD3C40;
    border-right: 4px solid #fff;

}
.howItWorks .step-rows .step-row:nth-child(even) > *:nth-last-child(1){
    border-left: 4px solid #fff;
    border-right: 4px solid #FD3C40;
}

.howItWorks .step-rows .step-row > *{
    width: calc(50% - 1.5px);
    padding: 0 130px;
}
.howItWorks .step-rows .step-row:not(:last-child) > div{
    padding-bottom: 240px;
}
.howItWorks .title {
    margin-bottom: 100px;
}

.howItWorks .first-col .step-number {
    display: flex;
    justify-content: flex-end;
}

.howItWorks .content img {
    height: 130px;
    object-fit: contain;
}

.howItWorks .first-col {
    text-align: right;
    border-right: 4px solid #FD3C40;
    padding-right: 130px;
}

.howItWorks .content h2 {
    border-radius: 50%;
    background-color: #FD3C40;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 81px;
    font-weight: normal;
}

.howItWorks .content .second-col .first-step h2 {
    color: #FD3C40;
    border: 1px solid #FD3C40;
    background-color: transparent;
}

.howItWorks .content h4 {
    color: #49566D;
    padding-bottom: 10px;
}

@media (max-width: 768px) {
    .howItWorks .step-rows  .step-row > *{
        border: 0 !important;
        width: 100%;
        padding: 0 40px;

    }
    .howItWorks .step-rows  .step-row{
        padding-right: 0;
        border-right:0;
        text-align: left;
        flex-wrap: wrap;
    }
}
