.forgotPassword .content {
    display: flex;
    justify-content: space-between;
}

.forgotPassword .content div:first-child {
    background-image: url(../../assets/images/login.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.forgotPassword .content .form {
    display: flex;
    flex-direction: column;
    padding: 200px 180px;
}

.forgotPassword .content > div {
    width: 50%;
}

.forgotPassword .content input {
    padding: 20px 60px;
    border: 1px solid #000;
    margin-bottom: 20px;
    font-size: 18px;
}

.pageTitle {
    color: #4D4D4D;
    margin-bottom: 10px;
    text-align: center;
    font-family: "PoppinsBold", sans-serif;
}

.forgotPassword .remember-me {
    display: flex;
    align-items: center;
}

.forgotPassword .remember-me input {
    margin-bottom: 0;
    position: relative;
    z-index: 9999;
    width: 21px;
    height: 21px;
    opacity: 0;
}

.forgotPassword .remember-me label {
    margin-left: 10px;
}

.forgotPassword .submit {
    background-color: #FF383B;
    border-radius: 6px;
    color: #FFF;
    padding: 15px;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    margin: 20px 0;
    font-family: "PoppinsSemiBold", sans-serif;
}

.forgotPassword .forgotPassword-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgotPassword .forgotPassword-link a {
    margin-left: 20px;
    color: #261F1A;
    font-family: "PoppinsSemiBold", sans-serif;
    font-size: 20px;
}
