

.products-block {
    display: flex;
    justify-content: space-between;
}

.products-page .product {
    margin-bottom: 20px;
}

.products-block .products {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    /*width: 80%;*/
}

.products-block .products .product {
    width: 23.5%;
}

.products-block .products .product:nth-child(4n) {
    margin-right: 0;
}

.breadcrumbs {
    padding: 40px 0 45px;
}

.products-page .filter {
    text-align: right;
    margin-bottom: 20px;
}

.products-page .product .product-title {
    /*height: 85px;*/
}

.products-page .filter svg {
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 70px auto;
}

.pagination li {
    margin-right: 10px;
}

.pagination li a {
    font-size: 20px;
    color: #000;

}
@media (max-width: 768px) {
    .products-block{
        flex-direction: column;
    }
    .products-block .sort-by {
        margin-bottom: 20px;
    }
}
