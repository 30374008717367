.products {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.sale-products .products {
    width: 65%;
}

.sale-products .products .product {
    margin-bottom: 20px;
    width: 30%;
}
@media (max-width: 768px) {
    .products{
        display: grid !important;
        grid-column-gap: 15px;
        grid-row-gap: 25px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
