.cart {
    display: flex;
    justify-content: space-between;
}

.order-summary {
    width: 30%;
}

.cart-products {
    width: 60%;
}

.order-summary h4 {
    font-weight: normal;
    padding-bottom: 25px;
    padding-top: 0;
}

.order-summary .summary p {
    font-size: 14px;
    line-height: 20px;
}

.order-summary .summary div {
    display: flex;
    justify-content: space-between;
}

.order-summary .subtotal {
    margin-bottom: 15px;
}

.order-summary .summary {
    padding-bottom: 30px;
    border-bottom: 1px solid #C4C4C4;
}

.cart-total {
    padding: 30px 0 20px;
}

.cart .buy-now,
.cart .buy-now a {
    display: block;
    text-align: center;
}

@media (max-width: 1024px) {
    .cart {
        flex-wrap: wrap;
    }

    .order-summary {
        width: 100%;
    }

    .cart-products {
        width: 100%;
    }

}
@media (max-width: 768px) {
    .cart {
        flex-wrap: wrap;
    }
    .cart-content .delete-product{
        position: absolute;
        right: 0;
        top: 10px;
    }
    .cartItem > div{
        width: 100%;
    }
    .cartItem .productImg{
        margin-bottom: 20px;
    }
    .cartItem .productImg img{
        width: 100%;
        object-fit: contain;
        margin: 0;
    }
    .cartItem{
        flex-wrap: wrap;
        padding-left: 0 !important;
        position: relative;

    }
    .buy-now a:first-child{
        margin-right: 0;
    }

    .order-summary {
        width: 100%;
    }

    .cart-products {
        width: 100%;
        margin-bottom: 40px;
    }
}
