.logIn .content {
    display: flex;
    justify-content: space-between;
}

.logIn .content div:first-child {
    background-image: url(../../assets/images/login.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.logIn .content .form {
    display: flex;
    flex-direction: column;
    padding: 200px 180px;
}

.logIn .content > div {
    width: 50%;
}

.logIn .content input {
    padding: 20px 60px;
    border: 1px solid #000;
    font-size: 18px;
}

.logIn .form input[type='password'] {
    margin-top: 20px;
}

.pageTitle {
    color: #4D4D4D;
    margin-bottom: 10px;
    text-align: center;
    font-family: "PoppinsBold", sans-serif;
}

.logIn .remember-me {
    display: flex;
    align-items: center;
}

.logIn .remember-me input {
    margin-bottom: 0;
    position: relative;
    z-index: 9999;
    width: 21px;
    height: 21px;
    opacity: 0;
}

.logIn .remember-me label {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.logIn .login {
    background-color: #FF383B;
    border-radius: 6px;
    color: #FFF;
    padding: 15px;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    margin: 20px 0;
    font-family: "PoppinsSemiBold", sans-serif;
}

.logIn .login-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logIn .login-link a {
    margin-left: 20px;
    color: #261F1A;
    font-family: "PoppinsSemiBold", sans-serif;
    font-size: 20px;
}

.remember-me .checkbox {
    position: absolute;
    top: 2px;
    left: 0;
    height: 21px;
    width: 21px;
    border: 1px solid #000;
    
}

/* Create the checkbox/indicator (hidden when not checked) */
.remember-me .checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.remember-me input:checked ~ .checkbox {
    background-color: #000;
}

/* Show the checkbox when checked */
.remember-me input:checked ~ .checkbox:after {
    display: block;
}

.remember-me {
    position: relative;
}

/* Style the checkbox/indicator */
.remember-me .checkbox:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.logIn input[type='password'] {
    margin-bottom: 10px;
}

.logIn .forgot-password {
    font-size: 14px;
    color: #032D23;
    text-align: right;
}