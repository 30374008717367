.add_products.profile-content {
    background-image: none;
}

.addProducts h4 {
    padding: 0 0 10px;
    font-weight: normal;
}

.upload-img {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 200px;
    border: 1px dashed #000000;
    margin-bottom: 25px;
}

.addProducts .profile-info,
.payment .profile-info {
    /*margin-right: 20px;*/
}

.addProducts .form {
    margin-left: 260px;
}

.upload-img svg {
    margin-bottom: 15px;
}

.upload-img .upload-photo {
    font-weight: 600;
    padding: 10px 0;
    text-align: center;
    width: 300px;
    margin-bottom: 15px;
    border: 1px solid #000;
    cursor: pointer;
}

.addProducts .form input,
.addProducts .form textarea {
    font-size: 16px;   
    color: #C4C4C4;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #000;
}

.addProducts .form textarea {
    resize: none;
    height: 125px;
}

.addProducts .form .product-details div div {
    display: flex;
    align-items: flex-start;
}

.addProducts .form .product-details div div:last-child textarea {
    margin-top: -68px;
}

.addProducts .form .product-details div div:first-child input,
.addProducts .form .product-details div div:last-child textarea {
    margin-right: 20px;
}

.addProducts .product-details {
    text-align: center;
}

/*.my-products > div {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

.my-products .product {
    width: 23.5%;
}

.add-products.profile-content .save-details {
    margin-bottom: 50px;
}

.my-products h4 {
    margin-bottom: 35px;
}
