.top-products .top-products-title {
    text-align: center;
    padding: 0 0 30px;
}

.top-products {
    margin-bottom: 115px;
}

.top-products .products {
    justify-content: space-between;
}