.filter {
    position: relative;
}

.filter-body {
    padding: 26px 21px;
    background: #fff;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 463px;
    text-align: left;
    z-index: 3;
}

.closeFilter {
    cursor: pointer;
    margin-bottom: 93px;
}

.filter-body .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
}

.filters {
    border-bottom: 1px solid #C4C4C4;
}

.filter-by-head {
    cursor: pointer;
    padding: 15px 0;

}

.filter-by-body {
    margin-bottom: 15px;

}

.filter-by {
    border-top: 1px solid #C4C4C4;
}

.category-body {
}
.firstLevel:after,.secondLevel:after{
    content: ">";
    font-size: 20px;
    font-weight: 900;
    margin-left: 10px;
    /*position: absolute;*/
    /*right: -10px;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/

}
.firstLevel{

}
.secondLevel{

}
.thirdLevel{

}
.level{
    position: relative;
    text-transform: capitalize;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .filter-body {
        padding: 20px 15px;
    }
    .closeFilter {
        margin-bottom: 30px;
    }
    .filter-body .title {
        font-size: 20px !important;
        line-height: 25px !important;
    }
    .filter-by-head{
        padding: 9px 0 !important;
    }
}

