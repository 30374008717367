.recommended .other-products {
    border-radius: 6px;
    text-align: center;
    padding: 18px 65px;
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    margin: 50px auto 90px ;
    display: flex;
    justify-content: center;
}

.recommended .product {
    text-align: center;
}

.recommended .recommended-title {
    text-align: center;
    padding: 90px 0 30px;
}

.recommended .products {
    display: flex;
}