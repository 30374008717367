.advertisement {
    background-image: url(../../assets/images/advertisement.png);
    padding: 55px 120px 15px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 90px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.advertisement h2 {
    font-family: "PoppinsBold", sans-serif;
    margin-bottom: 15px;
    color: #FFF;
}

.advertisement .start-shopping {
    background-color: transparent;
    border-radius: 6px;
    text-align: center;
    color: #FFF;
    border: 1px solid #FFF;
    padding: 10px 40px;
    font-family: "PoppinsBold", sans-serif;
    font-size: 18px;
}

.advertisement .content {
    padding-bottom: 50px;
}

@media (max-width: 768px) {
    .advertisement {
        flex-wrap: wrap;
        padding: 25px 30px 25px;
        margin-bottom: 50px;
    }
    .advertisement .content {
        padding-bottom: 30px;
    }

    .advertisement .start-shopping {
        padding: 5px 20px;
        font-size: 16px;
    }
}
