.product img {
    box-shadow: 0 4px 20px rgba(0,0,0,.25);
    border-radius: 10px;
}

.product {
    margin-right: 20px;
}

.product {
    width: 16%;
    margin-bottom: 50px;
}

.product img {
    width: 100%;
    height: 240px;
}


.product .price {
    color: #FF383B;
    background-color: #FF383B1A;
    padding: 5px 15px;
    border-radius: 16px;
    display: inline-block;
}

.product .product-title {
    padding: 15px 0;
    /*height: 110px;*/
}

.product {
    text-align: center;
}
@media (max-width: 768px) {
    .product{
        width: 100% !important;
    }
    .product img {
        height: auto !important;
    }
    .product .product-title {
        height: auto !important;
    }
    .product{
        margin-right: 0 !important;
        margin-bottom: 0 !important;
    }
    .add-products .add-new-products{
        padding-left: 15px;
        padding-right: 15px;
    }
    .addProducts .form{
        margin-left: 0 !important;
    }
}
