.verification .content {
    display: flex;
    justify-content: space-between;
}

.verification .content > div:first-child {
    background-image: url(../../assets/images/login.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.verification .content .form {
    display: flex;
    flex-direction: column;
    padding: 200px 180px;
}

.verification .code {
    display: flex;
    justify-content: center;
}

.verification .code input {
    margin-right: 25px;
}

.verification .content > div {
    width: 50%;
}

.verification .notice {
    color: #FF383B;
    text-align: center;
    margin-bottom: 35px;
}

.verification .content input {
    padding: 0 5px;
    border: 1px solid #334478 !important;
    margin-bottom: 20px !important;
    width: 50px !important;
    font-size: 18px !important;
    text-align: center;
    height: 50px !important;
    border-radius: 4px !important;
    font-family: sans-serif !important;
}

.pageTitle {
    color: #4D4D4D;
    margin-bottom: 10px;
    text-align: center;
    font-family: "PoppinsBold", sans-serif;
}

.verification .remember-me {
    display: flex;
    align-items: center;
}

.verification .remember-me input {
    margin-bottom: 0;
    position: relative;
    z-index: 9999;
    width: 21px;
    height: 21px;
    opacity: 0;
}

.verification .remember-me label {
    margin-left: 10px;
}

.verification .submit {
    background-color: #FF383B;
    border-radius: 6px;
    color: #FFF;
    padding: 15px;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    margin: 20px 0;
    font-family: "PoppinsSemiBold", sans-serif;
}

.verification .verification-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.verification .verification-link a {
    margin-left: 20px;
    color: #261F1A;
    font-family: "PoppinsSemiBold", sans-serif;
    font-size: 20px;
}
