.seller-img img {
    width: 100%;
}
.seller-img {
    margin-right: 20px;
    width: 100%;
}
.seller-info{
    width: 100%;
}
.seller .followers {
    margin-left: 95px;
}

.about-seller h4 {
    padding: 0 0 4px;
}

.seller-contact-info {
    margin-top: 15px;
}

.seller-contact-info div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.seller-contact-info svg {
    margin-right: 20px;
}

.seller-contact-info div a {
    color: #200E32;
    font-size: 18px;
}

.seller .btn {
    text-transform: uppercase;
    width: 345px;
    letter-spacing: 5px;
    margin: 0;
    font-weight: normal;
    padding: 12px 0;
}

.seller .seller-actions {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.seller .seller-actions button:not(.btn) {
    border: 1px solid #FD3C40;
    width: 220px;
    color: #FD3C40;
    padding: 15px;
    border-radius: 9px;
    background-color: transparent;
    letter-spacing: 3px;
}

.seller .seller-actions .send-message {
    margin: 0 20px;
}

.seller .seller-actions button.more {
    width: 100px;
}


.sellers-products h3 {
    margin-bottom: 35px;
    text-align: center;
    font-weight: normal;
}

.seller .products {
    justify-content: space-between;
}

.last-seen {
    font-size: 12px;
    margin-bottom: 0;
    color: #AEAEAE;
    text-align: right;
}
.seller-profile-content{
    background-image: none;
    margin-bottom: 90px;
    margin-top: 35px;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-column-gap: 150px;
    grid-template-columns: 1fr 1fr;
    position: relative;
}
@media (max-width: 768px) {
    .seller-img{
        margin-right: 0;
    }
    .seller-profile-content{
        grid-column-gap: 0px;
        grid-row-gap: 40px;
        grid-template-columns: 1fr;
    }
    .seller-info .followers{
        flex-wrap: wrap;
        margin-left: 0;
    }
    .followers div:not(:last-child) {
        margin-right: 20px;
    }
    .seller .seller-actions{
        flex-wrap: wrap;
    }
    .seller .btn{
        width: 100%;
        margin-bottom: 20px;
    }
    .seller .seller-actions button:not(.btn){
        width: auto;
        min-width: 150px;
        margin-left: 0;
    }
    .sellers-products{
        margin-bottom: 20px;
    }
}
