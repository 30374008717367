.section-title {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.section-title svg {
    cursor: pointer;
}

.section-title h2 {
    color: #FD3C40;
    margin-right: 20px;
    font-weight: normal;
}

.followers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.followers h6 {
    color: #B90000;
    font-weight: 600;
}

.editProfile .form {
    flex-direction: column;
}

.editProfile .profile-info {
    /*margin-right: 265px;*/
}

.editProfile .form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.followers div {
    text-align: center;
}

.followers div:not(:last-child) {
    margin-right: 130px;
}

.editProfile .form textarea {
    resize: none;
    height: 195px;
    margin-bottom: 20px;
    outline: none;
    padding: 15px;
    font-size: 16px;
}

.editProfile label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.editProfile input {
    margin-bottom: 20px;
    border: 1px solid #000;
    padding: 30px 15px;
    outline: none;
    font-size: 16px;
}

.change-password {
    display: flex;
    flex-direction: column;
    margin-left: 222px;
}

.change-password a:first-child {
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 10px;
}

.change-password a {
    font-size: 14px;
    color: #000;
}

.edit-profile-block {
    display: flex;
    align-items: flex-end;
    margin-bottom: 200px;
    width: 100%;
}

.profile-content .save-details {
    background-color: #FD3C40;
    padding: 10px 140px;
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 9px;
    width: 345px;
    margin: 35px auto 0;
}

.profile-content {
    background-image: url(../../assets/images/account-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 50px;
    display: grid;
    grid-column-gap: 150px;
    grid-template-columns: 20% calc(80% - 150px);
    position: relative;
}
input.editableName{
    font-family: "PoppinsRegular";
    font-size: 36px;
    color: #FD3C40;
    letter-spacing: 0.1em;
    border: 0;
    padding: 0;
    width: calc(100% - 25px);


}
@media (max-width: 1200px) {
    .profile-content{
        grid-column-gap: 50px;
        grid-template-columns: 40% calc(60% - 50px);
    }
    .edit-profile-block{
        flex-wrap: wrap;
    }
    .profile-info .logOut-btn{
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .profile-info .profile-nav a{
        font-size: 20px;
        margin-bottom: 15px;
    }
    .profile-info .profile-nav{
        padding: 150px 0 20px 0;
    }
    .profile-content label{
        width: 100%;
    }
    .profile-content img{
        height: 150px;
        object-fit: contain;
    }
    .profile-content input.editableName{
        font-size: 31px;
        letter-spacing: 0.07em;
    }
    .change-password {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-top: 20px;
        margin-right: 20px;
    }
}
@media (max-width: 768px) {
    .profile-info,.edit-profile-block{
        padding-right: 15px;
        padding-left: 15px;
    }
    .profile-content{
        grid-row-gap: 20px;
        grid-template-columns: 100%;
    }
    .edit-profile-block{
        flex-wrap: wrap;
    }
    .profile-info .logOut-btn{
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .profile-info .profile-nav a{
        font-size: 20px;
        margin-bottom: 15px;
    }
    .profile-info .profile-nav{
        padding: 150px 0 20px 0;
    }
    .editProfile label{
        width: 100%;
    }
    .profile-info img{
        height: 150px;
        object-fit: contain;
    }
    .profile-info input.editableName{
        font-size: 31px;
        letter-spacing: 0.07em;
    }
    .change-password {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-top: 20px;
        margin-right: 20px;
    }
}
