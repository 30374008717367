.payment .edit-profile-block {
    flex-direction: column;
    align-items: flex-start;
}

.card-type input {
    margin-bottom: 0;
    position: relative;
    z-index: 9999;
    width: 21px;
    height: 21px;
    opacity: 0;
    margin-right: 20px;
}

.card-type .radioBtn {
    position: absolute;
    top: 8px;
    left: 0;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    border: 2px solid #686868;
}

/* Create the radioBtn/indicator (hidden when not checked) */
.card-type .radioBtn:after {
    content: "";
    position: absolute;
    display: none;
    left: 3px;
    top: 3px;
    width: 11px;
    height: 11px;
    background-color: #15347A;
    border-radius: 50px;
}

/* Show the radioBtn when checked */
.card-type input:checked ~ .radioBtn:after {
    display: block;
}

.card-type input:checked ~ .radioBtn {
    border-color: #15347A;
}

.card-type input:checked ~ * {
    color: blue;
}

.card-type {
    display: flex;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, .6);
}

.payment .delete-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 0 20px auto;
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.payment .save {
    text-align: right;
}

.payment .delete-card span {
    margin-right: 10px;
    color: #757575;
    font-size: 12px;
}

.card-information {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.card-information input {
    padding: 10px 20px;
    border: 1px solid #C4C4C4;
    margin-top: 20px;
    font-size: 18px;
    color: #888888;
    width: 100%;
    max-width: 345px;
}

.card-information .error {
    /*margin-top: 0;*/
    /*margin-bottom: 10px;*/
}

.card-information .cvv {
    display: flex;
    align-items: center;
}

.card-information .cvv input {
    /*margin-bottom: 0;*/
    width: calc(100% - 20px);
    margin-right: 20px;
}

.card-information .cvv span {
    font-size: 12px;
    margin-top: 20px;
    color: #261F1A;
}

.cards .connected-card {
    margin-right: 20px;
    display: flex;
    flex-direction: column;

}

.cards .connected-card img {
    border-radius: 18px;
    width: 345px;
    height: 205px;
}

.cards .add-new {
    width: 345px;
    border-radius: 18px;
    height: 205px;
    border: 1px dashed #FD3C40;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #986262;
    background: #ededed;
    cursor: pointer;
    margin-bottom: 20px;

}

.cards {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
}

.card-type label {
    position: relative;
    margin-right: 80px;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #686868;
}

.card:after {
    content: url("../../assets/images/amp.svg");
    right: 0;
    bottom: -8px;
    position: absolute;
    z-index: 1;
    border-radius: 16px;
}

.card {
    z-index: 2;
    position: relative;
    background: linear-gradient(#197BBD, rgba(0, 0, 0, 0.33));
    border-radius: 16px;
    padding: 28px 23px;
    min-height: 206px;
    min-width: 345px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.card .card-number {
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    letter-spacing: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card .bottom-info > div {
    min-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.card .bottom-info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 18px;
}

.card .name {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.card .title {
    font-weight: 500;
    font-size: 7.17776px;
    line-height: 9px;
    margin-bottom: 6.7px;
    color: #FFFFFF;
}

.card span {
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #FFFFFF;
}
.cards-overflow-parent{
    max-width: 750px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
}

.cards-overflow{
    width:  100%;
}
.cards-child{
    display: flex;
    align-items: center;
}
@media (max-width: 1200px) {
    .cards{
        /*flex-wrap: wrap;*/
    }
    .cards-overflow-parent{
        margin-right: 0;
        margin-bottom: 20px;
        overflow-y: hidden;
    }


}
@media (max-width: 768px) {
    .card-information{
        width: 100%;
    }
    .card-information input{
        max-width: 100%;
    }
    .card{
        min-width: 330px;
    }
}
