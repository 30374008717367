.mg-b-0{
    margin-bottom: 0 !important;
}
::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'PoppinsRegular';
    src: url('../src/app/assets/fonts/Poppins-Regular.eot');
    src: url("../src/app/assets/fonts/Poppins-Regular.eot?#iefix")format("embedded-opentype"),
    url("../src/app/assets/fonts/Poppins-Regular.woff") format("woff"),
    url("../src/app/assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsBold';
    src: url('../src/app/assets/fonts/Poppins-Bold.eot');
    src: url("../src/app/assets/fonts/Poppins-Bold.eot?#iefix")format("embedded-opentype"),
    url("../src/app/assets/fonts/Poppins-Bold.woff") format("woff"),
    url("../src/app/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('../src/app/assets/fonts/Poppins-SemiBold.eot');
    src: url("../src/app/assets/fonts/Poppins-SemiBold.eot?#iefix")format("embedded-opentype"),
    url("../src/app/assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("../src/app/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

*{
    font-family: 'PoppinsRegular';
}
.size{
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
}
h1 {
    font-size: 54px;
    line-height: 74px;
}

h2 {
    font-size: 36px;
    line-height: 46px;
}

h3 {
    font-size: 30px;
    line-height: 40px;
}

h4 {
    font-size: 24px;
    line-height: 34px;
}

h5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
}

h6 {
    font-size: 18px;
    line-height: 28px;
    font-weight: normal;
}

button,input{
    border: none;
    outline: none;
}

button{
    cursor: pointer;
}

a{
    text-decoration: none;
    font-size: 16px;
}

ul li{
    list-style-type: none;
}

p {
    font-size: 16px;
    line-height: 26px;
}

.sample-page {
    padding: 105px 0;
}

.title {
    font-weight: normal;
    margin-bottom: 35px;
}
.d-none{
    display: none;
}
.btn {
    background-color: #FD3C40;
    padding: 10px 0;
    text-align: center;
    color: #FFF;
    font-weight: 600;
    border-radius: 9px;
    width: 220px;
    margin: 35px auto 0;
    font-size: 18px;
}

.error {
    display: none;
    color: #FF2E00 !important;
    font-size: 12px !important;
    margin-top: 8px;
}
.success {
    display: none;
    font-size: 12px !important;
    margin-top: 8px;
}

.errorInput {
    border-color: #FF383B!important;
    color: #FF383B;
}

.mobile-style{
    display: none;
}
.tablet-style{
    display: none;
}
.text-center{
    text-align: center;
}
.message{
    font-size: 14px;
}
.d-flex{
    display: flex;
}
.align-center{
    align-items: center;
}
.align-end{
    align-items: flex-end;
}
.justify-center{
    justify-content: center;
}
.justify-end{
    justify-content: flex-end;
}
.justify-start{
    justify-content: flex-start;
}
.justify-between{
    justify-content: space-between;
}
.flex-column{
    flex-direction: column;
}
.btn-shadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container-fluid{
    max-width: 1200px;
}
.ml-auto{
    margin-left: auto;
}
.mr-auto{
    margin-right: auto;
}
.w-100{
    width: 100%;
}
.mg-b-20{
    margin-bottom: 20px;
}
.ml-20{
    margin-left: 20px;
}
.mr-20{
    margin-right: 20px;
}
.mg-t-8{
    margin-top: 8px;
}
.mg-t-45{
    margin-top: 45px;
}
.flex-1{
    flex: 1;
}
.btn-main{
    background: #FF383B;
    border-radius: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 2.5px;
    color: #FFFFFF;
    width: max-content;
    min-width: 220px;
    text-align: center;
    padding: 9px 20px;
}
.pointer{
    cursor: pointer;
}
.MuiCheckbox-colorPrimary.Mui-checked,.MuiSlider-root,.MuiRadio-colorPrimary.Mui-checked{
    color: #4D4D4D !important;
}
.MuiSlider-rail,.MuiSlider-track{
    height: 3px !important;
}
.MuiSlider-rail{
    background-color: #c4c4c4;
}
.disabled{
    pointer-events: none;
    opacity: 0.5;
}
.color-red{
    color: #FD3C40;
}
.bold{
    font-weight: bold;
}
.view-all{
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: 0.06em;
    color: #4E4E4E;
    text-decoration: underline;
    cursor: pointer;
}
.size-chart{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.06em;

    color: #888888;
}
.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
}
.rating > span {
    display: inline-block;
    position: relative;
    width: 20px;
    font-size: 20px;
    cursor: pointer;
    color: #FFC700;

}
.rating > span:hover:before,
.rating > span:hover ~ span:before {
    content: "\2605";
    position: absolute;
    color: #FFC700;

}
@media (max-width: 1024px) {
    .web-style{
        display: none;
    }
    .tablet-style{
        display: block !important;
    }
    h1 {
        font-size: 40px;
        line-height: 50px;
    }

    h2 {
        font-size: 30px;
        line-height: 40px;
    }

    h3 {
        font-size: 24px;
        line-height: 36px;
    }

    h4 {
        font-size: 22px;
        line-height: 30px;
    }

    h5 {
        font-size: 18px;
        line-height: 28px;
        font-weight: normal;
    }

    h6 {
        font-size: 16px;
        line-height: 26px;
        font-weight: normal;
    }

    button,input{
        border: none;
        outline: none;
    }

    button{
        cursor: pointer;
    }

    a{
        text-decoration: none;
        font-size: 14px;
    }

    ul li{
        list-style-type: none;
    }

    p {
        font-size: 14px;
        line-height: 20px;
    }

    .sample-page {
        padding: 70px 0;
    }

    .title {
        margin-bottom: 20px;
    }

    .btn {
        padding: 10px 0;
        width: 190px;
        margin: 20px auto 0;
        font-size: 16px;
    }

    .error {
        display: none;
        color: #FF2E00;
        font-size: 10px;
        margin-top: 8px;
    }
}

@media (max-width: 768px) {
    .web-style{
        display: none;
    }
    .mobile-style{
        display: block;
    }
}
